import React from "react"
import Layout from "../../components/layout"
import "../team.css"
import Contact from "../../components/contact"
import About from "../../components/about"
import TeamSection from "../../components/teamSection"

export default () => {
  const lang = "fr"
  return (
    <Layout
      lang={lang}
      pageUrl="/team"
      seoTitle="Team: nos experts web et digital"
      seoDescription="Ingénieur Informatique, visionnaire produit ou encore geek du design, découvrez notre A-Team."
    >
      <div id="about-background">
        <About lang={lang} />
      </div>
      <div id="team-background">
        <TeamSection lang={lang} />
      </div>
      <div id="contact-team-background">
        <Contact lang={lang} />
      </div>
    </Layout>
  )
}
